<template>
  <div class="row">
    <div class="col-md-12">
      <dashboard-box :back="true" class="px-4" :back-component-name="parentRouteName || null">
        <template v-slot:title>
          <span>{{ $t("companies.create_company") }}</span>
        </template>
        <template v-slot:preview>
          <div class="row px-4 py-4">
            <div class="col-8">
              <div class="row">
                <div class="col-12">
                  <text-input :is-inline="true" :helper-text="$t('companies.please_enter_company_name')"
                    :placeholder="$t('companies.please_enter_company_name')" :title="$t('companies.company_name')"
                    :required="true" :model.sync="company.name" :is-valid="
                      company.name !== null && company.name.length !== 0
                    "></text-input>
                  <textarea-input :is-inline="true" :helper-text="
                    $t('companies.please_enter_company_address')
                  " :placeholder="
                    $t('companies.please_enter_company_address')
                  " :required="false" :model.sync="company.address" :title="$t('companies.company_address')" :is-valid="
                    company.address != null && company.address.length !== 0
                      ? true
                      : null
                  "></textarea-input>
                  <text-input :is-inline="true" :helper-text="
                    $t('companies.please_enter_tax_administration')
                  " :placeholder="
                    $t('companies.please_enter_tax_administration')
                  " :title="$t('companies.tax_administration')" :required="false"
                    :model.sync="company.tax_administration" :is-valid="
                      company.tax_administration != null &&
                      company.tax_administration.length !== 0
                        ? true
                        : null
                    "></text-input>
                  <text-input :is-inline="true" :helper-text="$t('companies.please_enter_tax_number')"
                    :placeholder="$t('companies.please_enter_tax_number')" :title="$t('companies.tax_number')"
                    :required="false" :model.sync="company.tax_number" :is-valid="
                      company.tax_number != null &&
                      company.tax_number.length !== 0
                        ? true
                        : null
                    "></text-input>

                    <text-input :is-inline="true" :helper-text="$t('companies.please_enter_email')"
                    :placeholder="$t('companies.please_enter_email')" :title="$t('companies.email')"
                    :required="false" :model.sync="company.email" :is-valid="
                      company.email != null &&
                      company.email.length !== 0
                        ? true
                        : null
                    "></text-input>
                </div>
              </div>
            </div>
            <div class="col-4 text-center">
              <img v-if="company.avatar !== null" :src="avatar_file" alt="" :class="{
                'w-100px h-100px rounded-lg mb-5': company.avatar !== null,
              }" />
              <span v-else v-html="
                getIconByKey('icons.meeting.company-logo', {
                  class: 'w-75px h-75px d-inline-block',
                })
              ">
              </span>
              <file-input @fileChange="onProfileImageChange" :model.sync="company.avatar" :is-inline="false"
                :title="$t('companies.company_profile_image')" :helper-text="
                  $t('companies.please_select_company_profile_image')
                " :required="true" :error="
                company.avatar == null && avatar_file != null
                    ? $t('companies.company_profile_image_cant_be_empty')
                    : null
                "></file-input>
            </div>
          </div>

          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-sm btn-outline-danger mr-3" @click="$router.go(-1)">
              {{ $t("general.back") }}
            </button>
            <button type="button" class="btn btn-sm btn-success" :disabled="!isFormValid" @click="submitForm">
              {{ $t("general.save") }}
            </button>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>
  
<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import TextInput from "@/assets/components/inputs/TextInput";
import TextareaInput from "@/assets/components/inputs/TextareaInput";
import FileInput from "@/assets/components/inputs/FileInput";

import {
  ERROR,
  LOADING,
  SUCCESS,
  GET_ITEMS,
  CREATE_ITEM,
} from "@/core/services/store/REST.module";

export default {
  name: "CompaniesCreateEdit",
  components: {
    FileInput,
    TextareaInput,
    TextInput,
    DashboardBox,
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      error: ERROR,
      success: SUCCESS,
    }),
    isFormValid() {
      if (this.company.name===null) return false;
      if (this.company.address===null) return false;
      if (this.company.tax_administration===null) return false;
      if (this.company.tax_number===null) return false;
      if (this.company.avatar===null) return false;
      return true;
    }
  },
  data() {
    return {
      id: null,
      parentRouteName: null,
      parentName: null,
      avatar_file: null,
      infiniteId: 1,
      company: {
        name: null,
        address: null,
        tax_administration: null,
        tax_number: null,
        avatar: null,
        email: null,
      },
    };
  },
  methods: {
    onProfileImageChange(payload) {
      this.avatar_file = payload.blob;
    },
    getCompany() {
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/companies/" + this.id,
        })
        .then((result) => {
          if (result.status) {
            self.company = result.data;
            self.avatar_file = self.company.avatar_file
          }
        });
    },
    submitForm() {
      if (!this.isFormValid) {
        this.sweetAlertError(
          this.$t("companies.please_fill_required_fields")
        );
        return;
      }
      let self = this;
      let contents = new FormData();
      let message = this.id == null ? this.$t('general.successfully_created') : this.$t('general.successfully_updated');
      contents.append("name", this.company.name);
      contents.append("address", this.company.address);
      contents.append("tax_administration", this.company.tax_administration);
      contents.append("tax_number", this.company.tax_number);
      contents.append("email", this.company.email);

      if (this.company.avatar) {
        contents.append("avatar", this.company.avatar);
      }
      this.$store.dispatch(CREATE_ITEM, {
        url: this.id ? 'api/companies/' + this.id : 'api/companies',
        acceptPromise: true,
        contents: contents,
      })
        .then((result) => {
          self
            .sweetAlertSuccess(self.$t(message))
            .then((_) => {
              self.$router.go(-1);
            });
        });

    },
  },
  mounted() {
    let id = this.$route.params.id;
    if (id != null) {
      this.id = id;
      this.getCompany();
    }
    this.parentName = this.$route.params.parentName;
    this.parentRouteName = this.$route.params.parentRouteName;

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t(this.parentName || "companies.parent"),
        route: this.parentRouteName || "dashboard",
      },
      { title: this.id == null ? this.$t("companies.create_company") : this.$t('companies.update_company') },
    ]);
  },
};
</script>
  
<style scoped lang="scss">
.image-section {
  img {
    text-align: center;
  }
}
</style>
  